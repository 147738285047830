(function() {
  'use strict';

  angular.module('sharedServices').directive('metadatacomments', metadatacomments);

  metadataCommentsController.$inject = ['MioHttp'];

  function metadatacomments() {
    var directive = {
      restrict: 'A',
      scope: {
        model: '=model'
      },
      controller: metadataCommentsController,
      controllerAs: 'metadataCommentsVm'
    };

    return directive;
  }

  function metadataCommentsController(MioHttp) {
    MioHttp.get('/gws/dashboard/ui/metadata').then(function(response) {
      var metadata = response.data;

      $('#environment').attr('content', metadata.env);
      $('#version').attr('content', metadata.ver);

      if (metadata.ga && ga) {
        ga('create', metadata.ga, 'auto', {
          sampleRate: 100
        });
        ga('send', 'pageview');
      }
    });
  }
})();
