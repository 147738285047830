/* global JL */
(function() {
  'use strict';

  function isAngularMessage(messageString) {
    if (!messageString) {
      return false;
    }

    return messageString && typeof messageString === 'string'
      && (messageString.indexOf('[$rootScope') >= 0 || messageString.indexOf('[$injector') >= 0);
  }

  window.onerror = function(errorMsg, url, lineNumber, column, errorObj) {
    if (isAngularMessage(errorMsg)) {
      return false;
    }

    // Set a global flag so the loading overlay can show an error.
    window.errorThrown = true;

    // Send object with all data to server side log, using severity fatal,
    // from logger "onerrorLogger"
    JL('onerrorLogger').fatalException({
      message: errorMsg,
      url: url,
      line: lineNumber,
      column: column,
      location: window.location.href,
      stack: errorObj ? errorObj.stack : null
    }, errorObj);

    // Tell browser to run its own error handler as well
    return false;
  };

  angular.module('sharedServices').service('$log', function(SessionInfoService, $window) {
    JL.setOptions({
      defaultAjaxUrl: '/gws/dashboard/ui/logger'
    });

    var addRequestHeaders = function(xhr, json) {
      var csrfToken = SessionInfoService.getCsrfToken();
      xhr.setRequestHeader(csrfToken.headerName, csrfToken.token);

      // Undo the jsnlog libraries stupid stringify of the message obj.
      json.lg.forEach(function(log) {
        log.m = JSON.parse(log.m);
        if (typeof log.m.location === 'object' && log.m.location._string) {
          log.m.location = log.m.location._string;
        } else if (typeof log.m.location === 'object') {
          log.m.location = '';
        }
      });
    };

    var appender = JL.createAjaxAppender('csrfTokenAppender');
    appender.setOptions({
      beforeSend: addRequestHeaders
    });

    JL().setOptions({
      appenders: [appender]
    });

    this.log = function(msg) {
      JL().trace({
        message: msg
      });
      $window.console.trace(msg);
    };
    this.debug = function(msg) {
      JL().debug({
        message: msg
      });
      $window.console.debug(msg);
    };
    this.info = function(msg) {
      JL().info({
        message: msg
      });
      $window.console.info(msg);
    };
    this.warn = function(msg) {
      JL().warn({
        message: msg
      });
      $window.console.warn(msg);
    };
    this.error = function(error, url, data, method, errorCode) {
      // Set a global flag so the loading overlay can show an error.
      window.errorThrown = true;

      JL().error({
        message: error.message ? error.message : error,
        referrer: window.location.href,
        location: url || window.location.href,
        data: JSON.stringify(data),
        method: method + '/' + errorCode,
        stack: error.stack ? error.stack : null
      });
      $window.console.error(error);
    };
  }).factory('$exceptionHandler', function() {
    return function(exception, cause) {
      if (!isAngularMessage(exception.message)) {
        // Set a global flag so the loading overlay can show an error.
        window.errorThrown = true;

        JL().fatalException(cause, exception);
      }
      throw exception;
    };
  });
})();
