(function() {
  'use strict';

  angular.module('sharedServices').service('Keys',
    function(Find, BrowserUtils) {
      this.namedEvents = [];
      this.suspended = false;
      this.assignedShortcuts = {};

      /*
            Registers a named event to a given Keyboard.js keys combo and callbacks.
             */
      this.on = function(namedEvent, keys, downCallback, upCallback) {
        var self = this;

        if (!keys || keys === '') {
          throw new Error('Cant bind an empty string key.');
        }

        // see if something is already bound for this name, if so remove it and clear bindings.
        self.clear(namedEvent);

        var downWrapper = function(evt, keys) {
          if (!self.suspended && downCallback) {
            downCallback(evt, keys);
          }
        };

        var upWrapper = function(evt, keys) {
          if (!self.suspended && upCallback) {
            upCallback(evt, keys);
          }
        };

        // create the new event and its bindings.
        var newBinding = KeyboardJS.on(keys, downWrapper, upWrapper);

        var name = typeof namedEvent === 'object' ? namedEvent.name : namedEvent;
        var label = typeof namedEvent === 'object' ? namedEvent.label : namedEvent;

        var newEvent = {
          name: name,
          label: label,
          key: keys,
          down: downWrapper,
          up: upWrapper,
          binding: newBinding
        };
        self.namedEvents.push(newEvent);

        // TODO(Seb): This assignment to assignedShortcuts should be updated on 'change' and 'clear' operations.
        var newShortcuts = this.__getSplitForAssignedShortcuts(keys, [',', '>', '+'], []);
        newShortcuts.forEach(function(ns) {
          self.assignedShortcuts[ns] = true;
        });
      };

      /**
             * Recursively splits incoming keys on the provided split tokens.
             */
      this.__getSplitForAssignedShortcuts = function(keys, splitTokens) {
        var self = this;

        if (typeof keys === 'string') {
          return self.__getSplitForAssignedShortcuts([keys], splitTokens);
        }

        if (!splitTokens || splitTokens.length === 0) {
          return keys;
        }

        var firstToken = splitTokens.splice(0, 1);
        var newKeys = [];
        keys.forEach(function(k) {
          var splitted = k.split(firstToken);
          splitted.forEach(function(s) {
            var trimmed = s.trim();
            if (newKeys.indexOf(trimmed) < 0) {
              newKeys.push(trimmed);
            }
          });
        });

        return self.__getSplitForAssignedShortcuts(newKeys, splitTokens);
      };

      /**
             * Changes the key for the given event name.
             */
      this.change = function(namedEvent, keys) {
        var self = this;
        var name = typeof namedEvent === 'object' ? namedEvent.name : namedEvent;

        // see if something is already bound for this name, if so remove it and clear bindings.
        var existingEvent = self.get(namedEvent);
        if (!existingEvent) {
          console.error('Could not change key binding ' + name);
          return;
        }

        // Clear the existing event and add a new using the old callbacks.
        self.clear(namedEvent);
        self.on(namedEvent, keys, existingEvent.down, existingEvent.up);
      };

      this.clear = function(namedEvent) {
        var self = this;

        // see if something is already bound for this name, if so remove it and clear bindings.
        var existingEvent = self.get(namedEvent);
        if (existingEvent) {
          existingEvent.binding.clear();
          self.namedEvents.splice(self.namedEvents.indexOf(existingEvent), 1);

          var splitShortcuts = self.__getSplitForAssignedShortcuts(existingEvent.key, [',', '>', '+'], []);
          splitShortcuts.forEach(function(ss) {
            delete self.assignedShortcuts[ss];
          });
        }
      };

      /**
             * Suspends all key bindings. Usefull for example when a user has put focus on a text box and we dont
             * want page wide keybindigns to interfeer with the text box.
             */
      this.suspendKeys = function(isSuspended) {
        var self = this;
        self.suspended = isSuspended;
      };

      this.get = function(namedEvent) {
        var self = this;
        var name = typeof namedEvent === 'object' ? namedEvent.name : namedEvent;

        var existingEvent = Find.find(self.namedEvents, {
          name: name
        });
        return existingEvent;
      };

      /**
             *
             * @param keyToBind
             * @returns {*}
             */
      this.customizeNumKeyText = function(keyToBind) {
        if (BrowserUtils.isChromeOnMac() && keyToBind.toLowerCase().indexOf('num') === 0) {
          return keyToBind.substr(3);
        }
        return keyToBind;
      };
    });
})();
