(function() {
  'use strict';

  angular.module('sharedServices')
    .constant('breakServiceEvents', {
      onBreak: 'onBreak'
    });

  angular
    .module('sharedServices')
    .factory('BreakService', breakService);

  breakService.$inject = ['$timeout', '$q', 'MioHttp', '$rootScope', 'breakServiceEvents', 'DateTime'];

  function breakService($timeout, $q, MioHttp, $rootScope, breakServiceEvents) {
    var initDeferred = $q.defer();
    var onBreak = false;
    var onBreakStartTime = null;

    var service = {
      isOnBreak: isOnBreak,
      goOnBreak: goOnBreak,
      resumeWork: resumeWork,
      checkServerIfOnBreak: checkServerIfOnBreak,
      getBreakStart: getBreakStart
    };

    init();

    return service;

    function isOnBreak() {
      if (initDeferred) {
        return initDeferred.promise;
      } else {
        return $.when(onBreak);
      }
    }

    function getBreakStart() {
      return onBreakStartTime;
    }

    /*
     * These functions previously notified the server of break-related events, but DEW does not (currently?) support
     * that. Some pages rely on the event that gets fired by __setOnBreak though, so I've left that in place for now.
     */
    function goOnBreak() {
      __setOnBreak(true, new Date());
      return Promise.resolve(true);
    }

    function resumeWork() {
      __setOnBreak(false);
      return Promise.resolve(true);
    }

    function checkServerIfOnBreak() {
      return Promise.resolve(false);
    }

    function __setOnBreak(value, startTime) {
      // inform client side of 'on break' status
      onBreak = value;
      onBreakStartTime = startTime;

      $rootScope.$broadcast(breakServiceEvents.onBreak, value);

      if (initDeferred) {
        var deferred = initDeferred;
        initDeferred = null;
        deferred.resolve(value);
      }
    }

    function pollServerForBreakStatus() {
      checkServerIfOnBreak().then(periodicCheckServerIfOnBreak, periodicCheckServerIfOnBreak);
    }

    function periodicCheckServerIfOnBreak() {
      $timeout(function() {
        pollServerForBreakStatus();
      }, 60000);
    }

    function init() {
      pollServerForBreakStatus(); // start polling for on break
    }
  }
})();
