(function() {
  'use strict';

  angular.module('sharedServices').directive('miodialog', [

    function() {
      return {
        restrict: 'A',
        scope: {
          show: '=show',
          buttons: '=buttons',
          size: '=size',
          validation: '=validation'
        },
        link: function(scope, element) {
          var elm = element[0];

          var heightWidth = getDialogSize();
          $(elm).dialog({
            modal: true,
            close: function(event) {
              // if the close was triggered from the dialog header X button, we're
              // guaranteed to not have a callback (which might perform apply() or digest() operations)
              // and therefore we're safe to set 'show' to false and apply the scope.
              if ($(event.currentTarget).hasClass('ui-dialog-titlebar-close')) {
                scope.show = false;
                scope.$apply();
              }
              $(elm).parent().off('keydown');
            },
            autoOpen: false,
            width: heightWidth.width,
            height: heightWidth.height,
            closeOnEscape: false
          });

          scope.$watch('validation', function(newValue) {
            updateButtons(newValue);
          });

          function updateButtons(isValid) {
            if (isValid !== undefined && scope.buttons && scope.buttons.length > 0) {
              // turn buttons on/off depending on if each respects validation
              scope.buttons.forEach(function(b) {
                // only process buttons which require validation.
                if (b.requiresValidDialog !== undefined) {
                  // loop over all UI buttons and find the matching one (by text)
                  $(elm).parent().find('button').each(function(i, e) {
                    // find matching by text
                    if (e.innerText.indexOf(b.text) >= 0) {
                      // update its state and class.
                      e.disabled = !isValid;
                      if (isValid) {
                        $(e).removeClass('ui-state-disabled');
                      } else {
                        $(e).addClass('ui-state-disabled');
                      }
                    }
                  });
                }
              });
            }
          }

          scope.$watch('show', function(newValue) {
            if (newValue) {
              if (scope.buttons && scope.buttons.length > 0) {
                var buttons = getButtons();
                scope.renderedButtons = buttons;
                $(elm).dialog('option', 'buttons', buttons);
              }
              $(elm).parent().find('button[isdefault=true]').addClass('mainButton');
              if (scope.size) {
                var heightWidth = getDialogSize();
                $(elm).dialog({
                  width: heightWidth.width,
                  height: heightWidth.height
                });
              }
              $(elm).dialog('open');

              $(elm).parent().keydown(function(e) {
                if (e.keyCode === $.ui.keyCode.ENTER) {
                  scope.renderedButtons.forEach(function(candidateButton) {
                    if (candidateButton.isDefault) {
                      if (candidateButton.requiresValidDialog && scope.validation) {
                        candidateButton.click();
                      } else if (!candidateButton.requiresValidDialog) {
                        candidateButton.click();
                      }
                    }
                  });
                } else if (e.keyCode === $.ui.keyCode.ESCAPE) {
                  $(elm).dialog('close');
                  scope.show = false;
                  scope.$apply();
                }
              });

              updateButtons(scope.validation);
            } else if (newValue === false) {
              $(elm).dialog('close');
            }
          });

          function getDialogSize() {
            var width = scope.size && scope.size.width ? scope.size.width : 300;
            var height = scope.size && scope.size.height ? scope.size.height : 'auto';
            return {
              height: height, width: width
            };
          }

          function getButtons() {
            var buttons = [];
            scope.buttons.forEach(function(button) {
              buttons.push(
                {
                  text: button.text,
                  isDefault: button.isDefault,
                  click: function() {
                    // if the button defined a callback, that callback might perform apply() or digest() operations.
                    // Therefore, that callback must adjust the 'show' property in order to hide this dialog.
                    if (button.callback) {
                      button.callback();
                    } else { // otherwise, if no callback, we're guaranteed to be safe to self adjust 'show' and apply the scope.
                      $(elm).dialog('close');
                      scope.show = false;
                      scope.$apply();
                    }
                  },
                  requiresValidDialog: button.requiresValidDialog
                }
              );
            });
            return buttons;
          }
        }
      };
    }
  ]);
})();
