(function() {
  'use strict';

  angular.module('sharedServices').directive('blurableinput', [

    function() {
      return {
        link: function(scope, element) {
          $(element).keyup(function(evt) {
            if (evt.keyCode === 13) {
              $(evt.target).blur();
            }
          });
        }
      };
    }
  ]);
})();
