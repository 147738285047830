(function() {
  'use strict';

  angular.module('sharedServices').directive('dynamicheightpanel', dynamicheightpanel);

  dynamicheightpanel.$inject = ['$rootScope', 'videoEvents'];

  function dynamicheightpanel($rootScope, videoEvents) {
    var directive = {
      restrict: 'A',
      templateUrl: function(elm, attrs) {
        return attrs.templateUrl;
      },
      link: link
    };

    return directive;

    function link(scope, element) {
      $rootScope.$on(videoEvents.HEIGHT_CALCULATED, function(evt, height) {
        $(element).height(height);
      });
    }
  }
})();
