(function() {
  'use strict';

  angular.module('sharedServices').service('BrowserUtils',
    function($window) {
      this.DOCUMENT_PADDING = 24;

      this.getDimensions = function() {
        var width = 0;
        var height = 0;
        if (typeof window.innerWidth === 'number') {
          // Non-IE
          width = window.innerWidth;
          height = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
          // IE 6+ in 'standards compliant mode'
          width = document.documentElement.clientWidth;
          height = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
          // IE 4 compatible
          width = document.body.clientWidth;
          height = document.body.clientHeight;
        }
        return {
          height: height, width: width
        };
      };

      // TODO (Tim): This method produces inaccurate measurements (see note). Use the 'V2' method instead.
      this.getAvailableHeight = function(consumerSelectors) {
        var self = this;

        // Note: Should be using "outerHeight" as "height" doesn't account for padding and borders. This is why
        // a lot of calls to this method finish with a fixed value.
        var available = self.getDimensions().height;
        available = available - self.DOCUMENT_PADDING;

        $.each(consumerSelectors, function(_, consumerSelector) {
          $(consumerSelector).each(function(_, el) {
            var jqEl = $(el);
            if (jqEl.is(':visible')) {
              available = available - jqEl.height();
            }
          });
        });

        return available;
      };


      /**
       * Returns the number of pixels remaining in the view port after reducing it by the given selectors.
       *
       * The selectors are assumed to be peers to each other (if they are nested, you'll get double counts).
       *
       * How it works:
       *   view port (inner height) - selectors (outer height)
       *
       * Note: If a selector refers to an element that is not visible, it will not be counted.
       */
      // TODO (Tim): Temp, until all usage of the default one have been replaced by this.
      this.getAvailableHeightV2 = function(selectors) {
        var self = this;

        var available = self.getDimensions().height;

        $(selectors.join(',')).each(function(_, el) {
          var jqEl = $(el);
          if (jqEl.is(':visible'))
            available -= jqEl.outerHeight();
        });

        return available;
      };

      this.getAvailableWidth = function(consumerSelectors) {
        var self = this;

        var available = self.getDimensions().width;
        available = available - self.DOCUMENT_PADDING;

        $.each(consumerSelectors, function(_, consumerSelector) {
          $(consumerSelector).each(function(_, el) {
            available = available - $(el).width();
          });
        });

        return available;
      };

      this.isOnMac = function() {
        return $window.navigator.userAgent.indexOf('Macintosh') >= 0;
      };

      // Sometimes, browser specific code is a necessary evil
      this.isChromeOnMac = function() {
        return this.isOnMac()
          && $window.navigator.userAgent.indexOf('Chrome') >= 0;
      };

      this.isChromeOnWindows = function() {
        return $window.navigator.userAgent.indexOf('Windows') >= 0
          && $window.navigator.userAgent.indexOf('Chrome') >= 0;
      };
    });
})();
