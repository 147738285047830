(function() {
  'use strict';

  angular.module('mioHttp', ['des'])
    .config([
      '$httpProvider',
      'SessionInfo',
      function($httpProvider, SessionInfo) {
        try {
          // Set the CSRF token, who's header/cookie name is customized "per-environment"
          var csrfToken = SessionInfo.csrfToken;
          $httpProvider.defaults.headers.common[csrfToken.headerName] = csrfToken.token;
        } catch (e) {
          console.log('mioHttp Error: ' + e);
        }
      }
    ]);

  angular.module('mioHttp').factory('MioHttp', mioHttpService);

  mioHttpService.$inject = ['$http', '$rootScope', '$log', '$q', 'SessionInfo'];

  // function mioHttpService($http, $rootScope, $log, $q, SessionInfo) {
  function mioHttpService($http, $rootScope, $log, $q) {
    var service = {
      get: get,
      post: post,
      put: put,
      delete: doDelete,
      invoke: invoke,
      removeHostAndPortFromUrl: removeHostAndPortFromUrl,
      getHubBasedS3AssetUrl: getHubBasedS3AssetUrl
    };

    return service;

    function getHubBasedS3AssetUrl(url) {
      return url;

      /**
       * As a temporary measure to allow users to work remotely we are "disabling" the redirecting to
       * the hub cache since agents to not have access to the cache at home.  When COVID-19 has passed
       * we can bring this code back so that agents can work from the hubs again.
       */
      // var hubId = SessionInfo.userDetails.hubId;
      //
      // var urlParser = /\/\/([\w-]+)\.s3.amazonaws.com\/([\w-/.]+.*)/;
      // var regexResult = urlParser.exec(url);
      // var bucket = regexResult[1];
      // var key = regexResult[2];
      //
      // return 'https://s3-' + hubId.toLowerCase() + '.carcounting.co/' + bucket + '/' + key;
    }

    function removeHostAndPortFromUrl(url) {
      return url ? url.replace(/^.*\/\/[^/]+/, '') : url;
    }

    function get(url, errorsHandled, triggerHttpCallMade) {
      return this.invoke({
        method: 'GET',
        url: url,
        data: null
      }, generateOptions(errorsHandled, triggerHttpCallMade));
    }

    function post(url, data, errorsHandled, triggerHttpCallMade) {
      return this.invoke({
        method: 'POST',
        url: url,
        data: data
      }, generateOptions(errorsHandled, triggerHttpCallMade));
    }

    function put(url, data, errorsHandled, triggerHttpCallMade) {
      return this.invoke({
        method: 'PUT',
        url: url,
        data: data
      }, generateOptions(errorsHandled, triggerHttpCallMade));
    }

    function doDelete(url, errorsHandled, triggerHttpCallMade) {
      return this.invoke({
        method: 'DELETE',
        url: url,
        data: null
      }, generateOptions(errorsHandled, triggerHttpCallMade));
    }

    function generateOptions(errorsHandled, triggerHttpCallMade) {
      return {
        errorsHandled: errorsHandled,
        triggerHttpCallMade: triggerHttpCallMade === undefined ? true : triggerHttpCallMade
      };
    }

    // performs default success and error implementations before any subsequent success or error implementations are chained/called.
    function invoke(configuration, options) {
      if (!options) {
        options = generateOptions(true, true);
      }
      return $http(configuration)
        .then(function(response) {
          if (options.triggerHttpCallMade) {
            $rootScope.$broadcast('httpCallMade');
          }
          // nothing extra
          return response;
        }, function(response) {
          var code = response.status;

          if (options.triggerHttpCallMade) {
            $rootScope.$broadcast('httpCallMade');
          }
          if (!options.errorsHandled) {
            // AJAX errors which occur during navigation (eg. we're canceling the ajax call) have a code of -1. Real errors
            // have a code of 400 or 500, for example. Ignore logging communication errors with code -1, null, undefined, etc.
            if (code && code !== -1) {
              var error = new Error('Communication error');
              $log.error(error, configuration.url, configuration.data, configuration.method, code);
              $rootScope.$broadcast('ajaxError', {});
            }
          }

          return $q.reject(response);
        });
    }
  }
})();
