(function() {
  'use strict';

  angular.module('sharedServices').service('StorageService', storageService);

  function storageService() {
    if (typeof Storage === 'undefined') {
      console.warn('Local storage not available.');
    }

    var service = {
      set: set,
      get: get,
      getBool: getBool,
      remove: remove
    };

    return service;

    function set(key, value) {
      var valueStr = value;
      // special handling of booleans
      if (typeof valueStr === 'boolean') {
        valueStr = valueStr.toString();
      }
      if (typeof valueStr !== 'string') {
        throw new Error('Can only store strings.');
      }

      localStorage.setItem(key, valueStr);
    }

    function get(key) {
      return localStorage.getItem(key);
    }

    /**
         * Returns a true/false or false if not found.
         * @param key
         */
    function getBool(key) {
      var val = this.get(key);
      return val === 'true';
    }

    function remove(key) {
      localStorage.removeItem(key);
    }
  }
})();
