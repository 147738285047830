MIO.VIDEO_KEY_BINDINGS = {
  // KeyBinder Events: No i18n occurs on these labels at the moment
  PLAY_PAUSE: {
    name: 'playPause',
    label: 'Play/Pause'
  },
  PLAY_PAUSE_HOLD: {
    name: 'playPauseHold',
    label: 'Play (hold)'
  },

  SEEK_BACK_LARGE: {
    name: 'seekBackLarge',
    label: 'Rewind Large'
  },
  SEEK_BACK_MEDIUM: {
    name: 'seekBackMed',
    label: 'Rewind Medium'
  },
  SEEK_BACK_SMALL: {
    name: 'seekBackSmall',
    label: 'Rewind Small'
  },
  SEEK_BACK_FRAME: {
    name: 'seekBackFrame',
    label: 'Rewind a Frame'
  },
  SEEK_BACK_FRAME_FULL_REWIND: {
    name: 'seekBackFrameFullRewind',
    label: 'Full Rewind'
  },
  SEEK_BACK_PLAYBACK_RATE: {
    name: 'seekBackPlaybackRate',
    label: 'Rewind @ Playback Pace'
  },

  SEEK_FORWARD_LARGE: {
    name: 'seekForwardLarge',
    label: 'Fast Forward Large'
  },
  SEEK_FORWARD_MEDIUM: {
    name: 'seekForwardMed',
    label: 'Fast Forward Medium'
  },
  SEEK_FORWARD_SMALL: {
    name: 'seekForwardSmall',
    label: 'Fast Forward Small'
  },
  SEEK_FORWARD_FRAME: {
    name: 'seekForwardFrame',
    label: 'Fast Forward a Frame'
  },

  PLAYBACK_RATE_INCREASE: {
    name: 'playbackUp',
    label: 'Increase Playback'
  },
  PLAYBACK_RATE_DECREASE: {
    name: 'playbackDown',
    label: 'Decrease Playback'
  },
  PLAYBACK_RATE_REALTIME: {
    name: 'playbackRealtime',
    label: 'Normal Playback'
  },

  EVENT_NEXT: {
    name: 'nextEvent',
    label: 'Next Event'
  },
  EVENT_PREV: {
    name: 'prevEvent',
    label: 'Previous Event'
  },
  EVENT_NEXT_SECONDARY: {
    name: 'nextEventSecondary',
    label: 'Next Secondary Event'
  },
  EVENT_PREV_SECONDARY: {
    name: 'prevEventSecondary',
    label: 'Previous Secondary Event'
  },

  DYNAMIC_RATE_CONTROL: {
    name: 'dynamicRateControl',
    label: 'Dynamic Rate Control'
  }
};
