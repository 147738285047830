(function() {
  'use strict';

  angular.module('mioHttp').controller(
    'sessionRefreshController', [
      '$scope', '$rootScope', '$interval', '$window', 'MioHttp', 'SessionInfoService', 'locale', 'Logout',
      function($scope, $rootScope, $interval, $window, MioHttp, SessionInfoService, locale, Logout) {
        $scope.sessionTimoutWarningMins = 25;
        $scope.sessionTimeout = 30;

        $scope.displaySessionDialog = false;
        $scope.lastHttpCallTime = moment();
        $scope.diffMins = 0;

        $scope.sessionDialogButtons = [
          {
            text: '',
            isDefault: true,
            callback: function() {
              MioHttp.put('/gws/auth/ui/session/ping').then(null, function(data) {
                console.log('Warning: session ping failed with status code ' + data.status);
                if (data.status === 403) {
                  // The user requested that we keep them logged in but when we did the session ping
                  // we got a 403. There isn't much to do but redirect the user to the login
                  // page.
                  $window.location.href = '/auth/';
                }
              });
              $scope.displaySessionDialog = false;
            }
          },
          {
            text: '',
            callback: function() {
              logout();
            }
          }
        ];

        locale.ready('common').then(function() {
          $scope.sessionDialogButtons[0].text = locale.getString('common.dialog-sessionExpire-keepSignedIn-button');
          $scope.sessionDialogButtons[1].text = locale.getString('common.dialog-sessionExpire-signout-button');
        });

        $rootScope.$on('httpCallMade', function() {
          $scope.lastHttpCallTime = moment();
        });

        $interval(function() {
          $scope.diffMins = parseInt(moment.duration(moment() - $scope.lastHttpCallTime).asMinutes());

          var shouldShowWarning = $scope.diffMins >= $scope.sessionTimoutWarningMins && $scope.diffMins < $scope.sessionTimeout;
          var shouldTimeoutSession = $scope.diffMins > $scope.sessionTimeout;

          // We keep track of the session timeout on the client side so that we don't repeatedly ping the server.
          // Ultimately the server is responsible for timing out the session, but the client makes a best guess
          // at when it should ask the server whether or not the session should be timed out.
          if (shouldShowWarning || shouldTimeoutSession) {
            MioHttp.get('/gws/identitymanagement/ui/users/lastActive', true, false)
              .then(function(response) {
                var data = response.data;
                if (!data && shouldTimeoutSession) {
                  logout();
                }

                if (data) {
                  if (data.shouldBeExpired) {
                    logout();
                  }
                  $scope.displaySessionDialog = data.shouldBeWarned;

                  $scope.lastHttpCallTime = moment(data.lastActive);
                }
              });
          }
        }, 1000 * 60);

        function logout() {
          Logout.logout().then(function() {
            MioHttp.post('/gws/logout').then(function() {
              $window.sessionStorage.removeItem('activeUserTradeId'); // clear user trade mocking
              $window.location.href = '/auth/';
            });
          });
        }
      }
    ]
  );
})();
