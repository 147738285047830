(function() {
  'use strict';

  angular.module('mioHttp').factory('onetimeHttpCache', onetimeHttpService);

  onetimeHttpService.$inject = ['MioHttp', '$q'];

  function onetimeHttpService(MioHttp, $q) {
    var cache = {};

    var service = {
      get: get,
      clear: clear
    };

    return service;

    /**
         * Returns a promise to return the requested object, while only making a GET call to the server once.
         */
    function get(url) {
      var cachedObject = cache[url];
      if (!cachedObject) { // this is a new url
        cache[url] = MioHttp.get(url).then(function(response) {
          cache[url] = response;
          return cache[url];
        });
        return cache[url];
      } else if (cachedObject.then) { // this is a promise like object.
        return cachedObject;
      } else { // this is the response.
        return $q.when(cachedObject);
      }
    }

    function clear(url) {
      cache[url] = null;
    }
  }
})();
