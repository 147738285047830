(function() {
  'use strict';

  angular.module('sharedServices').factory('Find', findService);

  findService.$inject = ['$filter'];

  function findService($filter) {
    var service = {
      exists: exists,
      countMatches: countMatches,
      find: find,
      findIndex: findIndex,
      findAll: findAll,
      min: min,
      findAllSorted: findAllSorted,
      next: next,
      prev: prev,
      binarySearch: binarySearch,
      findById: findById
    };

    return service;

    function exists(collection, predicate) {
      var item = find(collection, predicate);
      return item != null && item !== undefined;
    }

    function countMatches(collection, predicate) {
      return findAll(collection, predicate).length;
    }

    function find(collection, predicate) {
      var predicateFunc = __convertPredicateToFunc(predicate);
      var foundItem;
      collection.some(function(item) {
        var isMatch = predicateFunc(item);
        if (isMatch) {
          foundItem = item;
        }
        return isMatch;
      });
      return foundItem;
    }

    function findIndex(collection, predicate) {
      var predicateFunc = __convertPredicateToFunc(predicate);
      for (var i = 0; i < collection.length; i++) {
        if (predicateFunc(collection[i])) {
          return i;
        }
      }

      return -1;
    }

    function findAll(collection, predicate) {
      var predicateFunc = __convertPredicateToFunc(predicate);
      return $filter('filter')(collection, predicateFunc);
    }

    function min(collection, predicate) {
      var minValue = Infinity;
      var minItem = null;

      collection.forEach(function(c) {
        if (predicate(c) < minValue) {
          minValue = predicate(c);
          minItem = c;
        }
      });
      return minItem;
    }

    /*
         Finds all items matching matchPredicate in a sorted collection.  This function will do a linear search of
         collection until at least one item has been matched.  After the first match, the linear seach will continue
         until matchPredicate returns false and bailoutPredicate returns true.
         */
    function findAllSorted(collection, matchPredicate, bailoutPredicate) {
      var result = [];

      var startedMatching = false;
      collection.some(function(item) {
        var isMatch = matchPredicate(item);
        if (isMatch) {
          result.push(item);
          startedMatching = true;
        }

        return startedMatching && !isMatch && bailoutPredicate(item); // break on true
      });

      return result;
    }

    function next(collection, predicate) {
      var current = find(collection, predicate);
      var currentIndex = collection.indexOf(current);
      return collection.length - 1 >= currentIndex ? collection[currentIndex + 1] : null;
    }

    function prev(collection, predicate) {
      var current = find(collection, predicate);
      var currentIndex = collection.indexOf(current);
      return currentIndex > 0 ? collection[currentIndex - 1] : null;
    }

    function __convertPredicateToFunc(predicate) {
      if (typeof predicate === 'object') {
        var func = function(obj) {
          for (var key in predicate) {
            if (obj[key] !== predicate[key]) {
              return false;
            }
          }
          return true;
        };

        return func;
      } else if (typeof predicate === 'function') {
        return predicate;
      }
    }

    /**
         * This function will perform a binary search through the provided array and use the provided functions to
         * return the correct value.  equalFunction, startFunction, endFunction and baseCaseFunction must have the
         * signature:
         *      T (data, index, countItem)
         * The type T is the type that this binary search will return.
         * @param data The array being searched.  The items must be in ascending order.
         * @param low The low index of data for the binary search.
         * @param high The high index of data for the binary search.
         * @param item The item being searched for.
         * @param compareFunction A function that will compare the input item with items in the array.  It must have
         *                        the signature:
         *                          int (item itemBeingSearchedFor, item rhs)
         *                        It will return 0 if the items are equal, a positive number if
         *                        itemBeingSearchedFor > rhs and a negative number if itemBeingSearchedFor < rhs.
         * @param equalFunction The function to run when an exact match is found.
         * @param startFunction The function to run when the item being searched falls before the first element in
         *                      the array.
         * @param endFunction The function to run when the item being searched falls past the end of the array.
         * @param baseCaseFunction The function to run when the search has found consecutive items in data which
         *                         item falls between.  This function will be called with the index of the item
         *                         which precedes item.
         * @param recursiveFunction The function to run if a base case is not hit causing one of the provided
         *                          functions to run.  The signature of this function must be:
         *                              T (data, low, high, item)
         * @param additionalCheckFunction A function which will be passed to equalFunction, startFunction,
         *                                endFunction and baseCaseFunction which will allow them to do extra
         *                                filtering.
         * @returns {*}
         * @private
         */
    function binarySearch(data, low, high, item, compareFunction, equalFunction, startFunction, endFunction, baseCaseFunction, recursiveFunction, additionalCheckFunction) {
      var index = (low + high) / 2 | 0;
      var countAtIndex = data[index];

      var compareResult = compareFunction(item, countAtIndex);

      if (compareResult === 0) {
        return equalFunction(data, index, item, additionalCheckFunction);
      }

      if (compareResult < 0) {
        if (index === 0) {
          return startFunction(data, index, item, additionalCheckFunction);
        }

        var compareWithPreviousResult = compareFunction(item, data[index - 1]);
        if (compareWithPreviousResult > 0) {
          return baseCaseFunction(data, index - 1, item, additionalCheckFunction);
        }

        return recursiveFunction(data, low, index - 1, item, additionalCheckFunction);
      }

      // compareResult MUST BE > 0
      if (index === data.length - 1) {
        return endFunction(data, index, item, additionalCheckFunction);
      }

      var compareWithNextResult = compareFunction(item, data[index + 1]);
      if (compareWithNextResult < 0) {
        return baseCaseFunction(data, index, item, additionalCheckFunction);
      }

      return recursiveFunction(data, index + 1, high, item, additionalCheckFunction);
    }

    function findById(collection, id) {
      return find(collection, {
        id: id
      });
    }
  }
})();
