(function() {
  'use strict';

  angular.module('sharedServices').directive('miofloating', [

    function() {
      return {
        restrict: 'A',
        scope: {
          floatingMy: '=floatingMy',
          floatingAt: '=floatingAt',
          floatingOf: '=floatingOf',
          floatingCollision: '=floatingCollision',
          floatingShow: '=floatingShow'
        },
        link: function(scope, element) {
          var elm = element[0];

          var options = {};
          if ($(elm).find('.floatingHandle').length > 0) {
            options = {
              handle: '.floatingHandle'
            };
          }

          $(elm).draggable(options);

          scope.$watch('floatingShow', function(newValue) {
            if (newValue) {
              if (scope.floatingMy && scope.floatingAt && scope.floatingOf) {
                var newPosition = {
                  my: scope.floatingMy,
                  at: scope.floatingAt,
                  of: scope.floatingOf === 'window' ? $(window) : $(scope.floatingOf)
                };

                if (scope.floatingCollision) {
                  newPosition.collision = scope.floatingCollision;
                }

                $(elm).position(newPosition);
              }
            }
          });
        }
      };
    }
  ]);
})();
