(function() {
  'use strict';

  /**
     * A client service for interfacing with taskmaster.
     */
  angular.module('sharedServices').service('Logout',
    function($q) {
      this.logoutCallbacks = [];

      this.registerLogoutHandler = function(callback) {
        var obj = {
          cb: callback,
          unregister: function() { throw new Error('Not Implemented'); }
        };

        this.logoutCallbacks.push(obj);
        return obj;
      };

      this.logout = function() {
        var deferred = $q.defer();
        var asyncOperationsComplete = 0;
        var totalCallbacks = this.logoutCallbacks.length;

        this.logoutCallbacks.forEach(function(cbObj) {
          var onCbComplete = function() {
            asyncOperationsComplete++;

            if (asyncOperationsComplete === totalCallbacks) {
              deferred.resolve();
            }
          };

          var promise = cbObj.cb();
          if (promise) {
            promise.finally(onCbComplete());
          } else {
            onCbComplete();
          }
        });

        if (totalCallbacks === 0) {
          deferred.resolve();
        }

        return deferred.promise;
      };
    });
})();
