(function() {
  'use strict';

  angular.module('sharedServices')
    .constant('Roles', {
      // Roles List: should match the server definitions ('moduleId.role')
      // platform level
      DEVELOPER: 'ROLE_PLATFORM.DEVELOPER',
      SETTINGS_MANAGER: 'ROLE_PLATFORM.SETTINGS_MANAGER',
      USER_MANAGER: 'ROLE_PLATFORM.USER_MANAGER',
      TASK_MANAGER: 'ROLE_PLATFORM.TASK_MANAGER',
      TASK_WORKER: 'ROLE_PLATFORM.TASK_WORKER',
      CUSTOM_CLASS_MANAGER: 'ROLE_PLATFORM.CUSTOM_CLASS_MANAGER',

      ATR: {
        COUNTER: 'ROLE_ATR.COUNTER',
        CONFIGURER: 'ROLE_ATR.CONFIGURER',
        REVIEWER: 'ROLE_ATR.REVIEWER',
        VERIFIER: 'ROLE_ATR.VERIFIER',
        QUALIFIER: 'ROLE_ATR.QUALIFIER',
        CLASSIFIER: 'ROLE_ATR.CLASSIFIER',
        STUDY_MANAGER: 'ROLE_ATR.STUDY_MANAGER',
        NOTIFICATION_MANAGER: 'ROLE_ATR.NOTIFICATION_MANAGER'
      }
    })
    .constant('Authorities', {
      // Authority List: should match the server definitions ('moduleId.authority')
      // platform level
      STUDY_CREATE_FROM_TEMPLATE: 'PLATFORM.STUDY_CREATE_FROM_TEMPLATE',
      TASK_LIST: 'PLATFORM.TASK_LIST',
      TASK_EDIT: 'PLATFORM.TASK_EDIT',
      TASK_VIEW: 'PLATFORM.TASK_VIEW',
      // modules
      ATR: {
        STUDY_LIST: 'ATR.STUDY_LIST',
        ESCALATED_TASKS: 'ATR.ESCALATED_TASKS',
        GOLDEN_STUDY_CREATE: 'ATR.GOLDEN_STUDY_CREATE'
      },
      AD: {
        JOBS_LIST: 'AD.JOBS_LIST'
      },
      ZC: {
        JOBS_LIST: 'ZC.JOBS_LIST'
      }
    });
})();
