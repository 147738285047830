(function() {
  'use strict';

  angular.module('sharedServices').factory('AuthenticationService', authService);
  authService.$inject = ['$window', 'SessionInfoService'];

  function authService($window, SessionInfoService) {
    var API = {
      requireAuthority: requireAuthority,
      requireRole: requireRole,
      hasAuthority: hasAuthority,
      hasRole: hasRole,
      isLoggedInUser: isLoggedInUser
    };

    /** Kicks the user if they do not have any of the roles. */
    function requireRole(roles) {
      // roles group authorities, but under the hood they simply get flattened to an authority that is prefixed
      // with 'ROLE_' so we can use all the same methods
      requireAuthority(roles);
    }

    /** Kicks the user if they do not have any of the authorities. */
    function requireAuthority(authorities) {
      var isAllowed = hasAuthority(authorities);

      // send them to the login if they do not have permission
      if (!isAllowed)
        $window.location = '/auth/';
    }

    /** Indicates if the current user has any of the authorities. */
    function hasAuthority(authorities) {
      if (!angular.isArray(authorities))
        authorities = [authorities];

      var userAuth = SessionInfoService.getUserAuthorities();
      return authorities.some(function(testAuth) {
        return userAuth.indexOf(testAuth) !== -1;
      });
    }

    /** Indicates if the current user has any of the roles. */
    function hasRole(roles) {
      // see note on `requireRoles(roles)`
      return hasAuthority(roles);
    }

    /** Checks that the current logged in user matches the ID of the user given. */
    function isLoggedInUser(userId) {
      return userId === SessionInfoService.getUserId();
    }

    return API;
  }
})();
