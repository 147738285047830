(function() {
  'use strict';

  angular.module('sharedServices').directive('hotkey', [

    function() {
      return {
        restrict: 'A',
        scope: {
          hotkeyText: '=hotkeyText'
        },
        templateUrl: '/common/html/partials/hotkey.html',
        link: function(scope, element) {
          $(element).addClass('hotkey');
          __updateKeys();

          scope.$watch('hotkeyText', function(oldVal, newVal) {
            if (oldVal !== newVal) {
              __updateKeys();
            }
          });

          function __updateKeys() {
            if (!scope.hotkeyText) {
              return;
            }

            if (scope.hotkeyText === '+') {
              scope.keys = ['+'];
              return;
            }

            scope.keys = scope.hotkeyText.split(/[+>]+/); // split on + and >
            for (var i = 0; i < scope.keys.length; i++) {
              scope.keys[i] = scope.keys[i].trim();

              if (scope.keys[i] === 'plus') {
                scope.keys[i] = '+';
              } else if (scope.keys[i] === 'minus') {
                scope.keys[i] = '-';
              }
            }
          }

          scope.isLastKey = function(k) {
            return scope.keys.indexOf(k) !== scope.keys.length - 1;
          };
        }
      };
    }
  ]);
})();
