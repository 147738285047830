(function() {
  'use strict';

  angular.module('mioDialog', []);

  angular.module('mioDialog').controller(
    'httpErrorController', [
      '$scope', '$rootScope',
      function($scope, $rootScope) {
        $scope.displayHttpErrorDialog = false;

        $rootScope.$on('validationError', function(event, args) {
          $scope.title = args.heading;
          $scope.exception = args.exception;
          $scope.message = args.message;
          $scope.totalMessage = args.details;
          $scope.displayHttpErrorDialog = true;
        });
      }
    ]
  );

  angular.module('mioDialog').directive('mioerrordialog', [
    function() {
      return {
        restrict: 'A',
        link: function(scope, element) {
          var elm = element[0];

          $(elm).dialog({
            modal: true,
            close: function() {
              scope.displayHttpErrorDialog = false;
              scope.$apply();
            },
            autoOpen: false,
            buttons: [{
              text: 'Ok', click: function() { $(elm).dialog('close'); }
            }],
            width: 600,
            height: 500
          });

          scope.$watch('displayHttpErrorDialog', function(newValue) {
            if (newValue) {
              $(elm).dialog('option', 'title', scope.title);
              $(elm).dialog('open');
            } else {
              $(elm).dialog('close');
            }
          });
        }
      };
    }
  ]);
})();
