(function() {
  'use strict';

  angular.module('sharedServices').factory('SeekLimiterService', seekLimiterService);

  seekLimiterService.$inject = ['settingsService', 'settingsConstants'];

  function seekLimiterService(SettingsService, SettingsConstants) {
    var service = {
      updateSeekLimit: updateSeekLimit,
      getSeekLimit: getSeekLimit,
      resetSeekLimit: resetSeekLimit,
      isSeekable: isSeekable,
      setReadOnly: setReadOnly
    };

    var seekLimit = 0;
    var seekLimitEnabled = true;
    var readOnly = false;

    SettingsService.getSettingValueAsBool(SettingsConstants.COUNT_TASK_SEEK_LIMITING_ENABLED).then(function(result) {
      seekLimitEnabled = result;
    });

    function isSeekable(timestampMs) {
      return readOnly || !seekLimitEnabled || timestampMs <= seekLimit;
    }

    function updateSeekLimit(currentTimestampMs) {
      seekLimit = Math.max(seekLimit, currentTimestampMs);
    }

    function getSeekLimit() {
      return seekLimit;
    }

    function resetSeekLimit() {
      seekLimit = 0;
    }

    function setReadOnly(isReadOnly) {
      readOnly = isReadOnly;
    }

    return service;
  }
})();
