(function() {
  'use strict';

  angular.module('sharedServices').directive('csrfForm', [
    'SessionInfoService',
    function(SessionInfoService) {
      return {
        link: function(scope, element) {
          if (element && element[0] && element[0].localName && element[0].localName === 'form') {
            var csrfToken = SessionInfoService.getCsrfToken();
            $(element[0]).append('<input type="hidden" name="_csrf" value="' + csrfToken + '"/>');
          }
        }
      };
    }
  ]);
})();
