(function() {
  'use strict';

  angular.module('sharedServices').service('SessionInfoService', [
    'SessionInfo',
    function(SessionInfo) {
      this.getSessionInfo = function() {
        return SessionInfo;
      };

      this.getUserId = function() {
        return SessionInfo.userDetails.id;
      };

      this.getUserHubId = function() {
        return SessionInfo.userDetails.hubId;
      };

      this.getUserEmail = function() {
        // currently the ID is the users email
        return this.getUserId();
      };

      this.getUserEmailMd5 = function() {
        return SessionInfo.userDetails.idMd5;
      };

      this.getUserTrades = function() {
        // Note: trades should not be used for security guards
        return SessionInfo.userDetails.trades;
      };

      this.getUserAuthorities = function() {
        return SessionInfo.userDetails.authorities;
      };

      this.getCsrfToken = function() {
        return SessionInfo.csrfToken;
      };

      this.getReleaseVersionInfo = function() {
        return SessionInfo.releaseVersionInfo;
      };
    }
  ]);
})();
