(function() {
  'use strict';

  angular.module('sharedServices').service('DateTime',
    function() {
      this.DEFAULT_DATETIME_FORMAT = 'lll'; // a localized format
      this.DEFAULT_TIME_FORMAT = 'HH:mm:ss.SSS';
      this.DEFAULT_TIME_SHORT_FORMAT = 'h:mm:ss a';
      this.DATE_DETAILED_TIME_FORMAT = 'MMM D YYYY h:mm:ss.SSS A';
      this.DATE_SHORT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
      this.DATE_DAY_OF_YEAR_FORMAT = 'YYYY-MM-DD';
      this.DATE_CSV_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

      this.formatDuration = function(durationInput) {
        var duration = null;
        if (typeof durationInput === 'number') {
          duration = moment.duration(durationInput);
        } else {
          duration = durationInput;
        }

        // we want padding
        var h = duration.asHours() < 1 ? 0 : parseInt(duration.asHours());
        h = h < 10 ? '0' + h : h;
        var m = duration.minutes();
        m = m < 10 ? '0' + m : m;
        var s = duration.seconds();
        s = s < 10 ? '0' + s : s;
        var ms = duration.milliseconds();
        if (ms < 100 && ms > 10) {
          ms = '0' + ms;
        } else if (ms < 10) {
          ms = '00' + ms;
        } else {
          ms = ms + ''; // cast to string
        }
        ms = ms.substr(0, 3);

        return h + ':' + m + ':' + s + '.' + ms;
      };

      this.formatDateTimeWithHumanize = function(date, humanizeOnly, format) {
        var self = this;
        var formatToUse = format || self.DEFAULT_DATETIME_FORMAT;
        return self.__formatDateTimeWithHumanize(date, humanizeOnly, formatToUse);
      };

      this.formatDateTimeDetailWithHumanize = function(date, format) {
        var self = this;
        var formatToUse = format || self.DATE_DETAILED_TIME_FORMAT;
        return self.__formatDateTimeWithHumanize(date, false, formatToUse);
      };

      this.__formatDateTimeWithHumanize = function(date, humanizeOnly, format) {
        if (!date) {
          return '';
        }

        var dateStr = !humanizeOnly ? moment(date).format(format) + ' (' : '';
        var suffix = !humanizeOnly ? ')' : '';

        return dateStr + moment.duration(moment(date) - moment()).humanize(true) + suffix;
      };

      this.formatDateTime = function(date, format) {
        if (!date) {
          return '';
        }

        return moment(date).format(format);
      };

      /**
             * Returns an object describing the min/max datetime values of the given collection. The predicate describes
             * which attributes on the objects contain the datetime values to examine.
             * @returns {{min: Number, max: number}}
             */
      this.getMinAndMaxDateTimes = function(collection, predicate) {
        var min = Infinity;
        var max = 0;
        collection.forEach(function(obj) {
          if (obj[predicate.min]) {
            min = obj[predicate.min] < min ? obj[predicate.min] : min;
          }
          if (obj[predicate.max]) {
            max = obj[predicate.max] > max ? obj[predicate.max] : max;
          }
        });
        return {
          min: min, max: max
        };
      };

      this.overlaps = function(a, b, omitBoundsCheck) {
        // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        // (StartA <= EndB) and (EndA >= StartB)
        return omitBoundsCheck
          ? a.start < b.end && a.end > b.start
          : a.start <= b.end && a.end >= b.start;
      };
    });
})();
