(function() {
  'use strict';

  angular.module('sharedServices').constant('settingsConstants', {
    BINARY_URI: 'ACTIVE_CVAGENT_BINARY_URI',
    CLASS_SPEED_THRESHOLD: 'CLASS_SPEED_THRESHOLD',
    COUNT_REVIEW_RED_LINE_ENABLED: 'COUNT_REVIEW_RED_LINE_ENABLED',
    CV_CONFIDENCE_HIGHER_ACCURACY_THRESHOLD: 'CV_CONFIDENCE_HIGHER_ACCURACY_THRESHOLD',
    CV_CONFIDENCE_LOWER_ACCURACY_THRESHOLD: 'CV_CONFIDENCE_LOWER_ACCURACY_THRESHOLD',
    INTERNAL_STUDY_CREATION_ENABLED: 'INTERNAL_STUDY_CREATION_ENABLED',
    SKIP_COUNTREVIEW_WHEN_MEET_CV_THRESHOLD: 'SKIP_COUNTREVIEW_WHEN_MEET_CV_THRESHOLD',
    STUDY_EXPORT_ENABLED: 'STUDY_EXPORT_ENABLED',
    STUDY_LOCKING_ENABLED: 'STUDY_LOCKING_ENABLED',
    TASK_ASSIGNMENT_ROULETTE_ENABLED: 'TASK_ASSIGNMENT_ROULETTE_ENABLED',
    TRADE_PRIORITIZATION_ENABLED: 'TRADE_PRIORITIZATION_ENABLED',
    RAPID_CV_COUNT_CUTOFF: 'RAPID_CV_COUNT_CUTOFF',
    TRANSCODE_RATE: 'TRANSCODE_RATE',
    PROXY_REDIRECT_ENABLED: 'PROXY_REDIRECT_ENABLED',
    VIDEO_TRANSCODE_DURATION_MINUTES: 'VIDEO_TRANSCODE_DURATION_MINUTES',
    ESCALATIONS_PASSWORD_ENABLED: 'ESCALATIONS_PASSWORD_ENABLED',
    CV_PUBLISH_ENABLED: 'CV_PUBLISH_ENABLED',

    // Settings below have migrated to DEW settings system
    PREVENT_EARLY_AGENT_TASK_EXIT: 'atr.count.preventEarlyTaskExit',
    COUNT_SPEED_THRESHOLD: 'atr.count.speedThreshold',
    COUNT_TASK_SEEK_LIMITING_ENABLED: 'atr.count.enableTaskSeekLimiting',
    LIMIT_TASK_REWINDING_ENABLED: 'atr.count.enableTaskRewindLimiting',
    ASSIGN_COUNT_AND_CLASSIFY_TASKS_TO_CENTRAL: 'atr.count.assignTasksToCentral',
    RESTARTS_PASSWORD_ENABLED: 'atr.count.restartsRequirePassword',
    SUPERVISOR_OVERRIDE_PASSWORD: 'atr.count.supervisorOverridePassword',
    INITIAL_AGENT_SPEED_SLIDER_POSITION: 'atr.count.initialAgentSpeedSliderPosition',
    DEFAULT_TASK_LENGTH: 'atr.count.defaultTaskLengthMinutes',
    ENABLE_VOLUME_VERIFY_SUMMARY: 'atr.volumeverify.enableSummary'
  });

  angular.module('sharedServices').factory('settingsService', settingsService);

  settingsService.$inject = ['MioHttp', 'settingsConstants', '$q'];

  /**
   * A service for getting system settings.
   */
  function settingsService(MioHttp, settingsConstants, $q) {
    var __cachedPublicSettings = null;
    var __cachedPublicSettingsPromise = null;

    var service = {
      isCountReviewRedLineEnabled: isCountReviewRedLineEnabled,
      preventEarlyAgentTaskExit: preventEarlyAgentTaskExit,
      getDefaultTaskLength: getDefaultTaskLength,
      getSettingValueAsBool: getSettingValueAsBool,
      getSettingValue: getSettingValue,

      getAllSettings: getAllSettings,
      getAllSettingsIncludingRestricted: getAllSettingsIncludingRestricted,
      isEscalationPasswordRequired: isEscalationPasswordRequired,
      isRestartsPasswordRequired: isRestartsPasswordRequired,
      getMaxPlaybackRate: getMaxPlaybackRate
    };

    return service;

    function isEscalationPasswordRequired() {
      return getSettingValueAsBool(settingsConstants.ESCALATIONS_PASSWORD_ENABLED);
    }

    function isRestartsPasswordRequired() {
      return getSettingValueAsBool(settingsConstants.RESTARTS_PASSWORD_ENABLED);
    }

    function isCountReviewRedLineEnabled() {
      return getSettingValueAsBool(settingsConstants.COUNT_REVIEW_RED_LINE_ENABLED);
    }

    function preventEarlyAgentTaskExit() {
      return getSettingValueAsBool(settingsConstants.PREVENT_EARLY_AGENT_TASK_EXIT);
    }

    function getDefaultTaskLength() {
      return getSettingValueAsInt(settingsConstants.DEFAULT_TASK_LENGTH);
    }

    function getSettingValueAsBool(key) {
      return getSettingValue(key).then(function(booleanValue) {
        return booleanValue === 'true' || booleanValue === true;
      });
    }

    function getSettingValue(key) {
      return getAllSettings().then(function(publicSettings) {
        return publicSettings[key];
      });
    }

    // Returns a promise that resolves to a map of the public settings.
    function getAllSettings() {
      if (__cachedPublicSettings) {
        return $q.when(__cachedPublicSettings);
      }

      if (!__cachedPublicSettingsPromise) {
        __cachedPublicSettingsPromise
          = MioHttp.get('/dew/api/v4/settings/basic/raw').then(function(response) {
            __cachedPublicSettingsPromise = null;
            __cachedPublicSettings = response.data;
            return __cachedPublicSettings;
          });
      }

      return __cachedPublicSettingsPromise;
    }

    // Returns a promise that resolves to a map of all the settings.
    function getAllSettingsIncludingRestricted() {
      return MioHttp.get('/dew/api/v4/settings/all/raw').then(function(response) {
        return response.data;
      });
    }

    function getMaxPlaybackRate() {
      return 20;
    }

    /*
        Private methods
     */

    function getSettingValueAsInt(key) {
      return getSettingValue(key).then(function(intValue) {
        return parseInt(intValue);
      });
    }
  }
})();
