(function() {
  'use strict';

  /**
   * Applies the config for https://github.com/doshprompt/angular-localization to an angular application.
   */
  window.applyLocaleConfig = function(app) {
    app
      .value('localeSupported', [
        'en-US',
        'es-NI'
      ])
      .value('localeFallbacks', {
        en: 'en-US',
        es: 'es-NI'
      })
      .value('localeConf', {
        basePath: '/common/js/languages',
        defaultLocale: 'en-US',
        sharedDictionary: 'common',
        fileExtension: '.lang.json',
        persistSelection: true,
        cookieName: 'COOKIE_LOCALE_LANG',
        observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
        delimiter: '::',
        validTokens: new RegExp('^[\\w\\.-]+\\.[\\w\\s\\.-]+\\w(:.*)?$')
      });
  };
})();
