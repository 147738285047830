(function() {
  'use strict';

  var desModule = angular.module('des', []);

  var state = {
    bootstrapApp: null,
    documentRead: false,
    hasBootstrapped: false,
    sessionInfo: null,
    headerItemsFile: null
  };

  desModule.factory('BootstrapService', function() {
    function bootstrap() {
      function getSessionInfo() {
        var initInjector = angular.injector(['ng']);
        var $http = initInjector.get('$http');
        return $http.get('/gws/auth/ui/session/info');
      }

      function bootstrapAngular() {
        angular.bootstrap(document.body, [state.bootstrapApp]);
      }

      function bootstrapHandler(sessionInfo) {
        desModule.constant('SessionInfo', sessionInfo);
        bootstrapAngular();
      }

      function onSessionInfoSuccess(response) {
        state.sessionInfo = response.data;
        bootstrapHandler(state.sessionInfo);
      }

      function onSessionInfoError() {
        // The whole app is behind authentication; if we get an error here it's because they aren't authenticated
        var initInjector = angular.injector(['ng', 'angular-storage']);
        var $window = initInjector.get('$window');
        var store = initInjector.get('store');

        // capture the target page and redirect to the login screen
        store.set('target', $window.location.pathname + $window.location.search + $window.location.hash);
        $window.location = '/auth/';
      }

      if (state.sessionInfo) {
        bootstrapHandler(state.sessionInfo);
      } else {
        getSessionInfo().then(onSessionInfoSuccess, onSessionInfoError);
      }
    }

    var svc = {};

    svc.getHeaderItemsFile = function() {
      return state.headerItemsFile;
    };

    svc.setHeaderItemsFile = function(headerItemsFile) {
      state.headerItemsFile = headerItemsFile;
    };

    svc.getBootstrapApp = function() {
      return state.bootstrapApp;
    };

    svc.setBootstrapApp = function(appName) {
      if (!state.hasBootstrapped) {
        state.bootstrapApp = appName;
        if (state.documentReady) {
          bootstrap();
          state.hasBootstrapped = true;
        }
      } else {
        console.log('Unable to set bootstrap application name: application has already bootstrapped');
      }
    };

    svc._documentReady = function() {
      state.documentReady = true;
      if (!state.hasBootstrapped && state.bootstrapApp) {
        bootstrap();
        state.hasBootstrapped = true;
      }
    };

    svc._setSessionInfo = function(sessionInfo) {
      state.sessionInfo = sessionInfo;
    };

    return svc;
  });

  angular.element(document).ready(function() {
    var initInjector = angular.injector(['des']);
    var BootstrapService = initInjector.get('BootstrapService');
    BootstrapService._documentReady();
  });
})();
